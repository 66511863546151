@use "sass:math";
/*------------------------------------*\
  Modal
\*------------------------------------*/


.modal {
  background-color: var(--white);
  left: 0;
  opacity: 0; 
  padding: var(--base-spacing-oneandhalf);
  position: absolute;
  top: 0;
  transition: opacity $base-transition;
  visibility: hidden;
  width: 100%; 
  z-index: 100;

  &.active {
    opacity: 1;
    visibility: visible;
  }
  
  @include media-breakpoint-up(md) {
    border-radius: var(--base-border-radius);
    left: 50%;
    margin-left: - math.div($grid-width-md, 2);
    position: absolute;
    top: var(--base-spacing-quadruple);
    width: $grid-width-md;
  }
}

.modal__header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-right: var(--base-spacing-double);
}

.modal__close {
  background: transparent;
  border: 0;
  color: var(--grey-medium-400);
  margin-left: auto;
  width: var(--base-spacing-triple);
  height: var(--base-spacing-triple);
  padding: 0;
  position: absolute;
  right: var(--base-spacing) ;
  top: var(--base-spacing) ;
  border-radius: var(--base-border-radius);
  
  &:hover {
    color: var(--grey-dark-800);
    background: var(--grey-subtle-028-transparent);
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
    box-shadow: var(--base-focus-box-shadow);
  }
}

.modal__body {
  min-height: auto;
  padding: var(--base-spacing-double)  0;

}

.modal__footer {
  bottom: 0;
  position:relative;
  text-align: right;

  .btn--primary {
    float: right;
  }  

  .btn--tertiary {
    margin-right: var(--base-spacing);
  }
}


/**
  * Layover
  */

.modal-backdrop {
  background-color: var(--black);
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity $base-transition;
  visibility: hidden;
  width: 100%;
  z-index: 99;

  &.active {
    opacity: 0.4;
    visibility: visible;
  }
}