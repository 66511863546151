/*------------------------------------*\
  Icons
\*------------------------------------*/

/**
  * Base icon sizing
  */

$icon-size-small: 1.125rem;
$icon-size: 1.5rem;
$icon-size-large: 2rem;