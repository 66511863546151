/*------------------------------------*\
  Input
\*------------------------------------*/

$checkbox-radio-size: 1.25rem;

/**
  * Input group
  */

.input-group {
  margin-bottom: var(--base-spacing) ;

  > .text-small {
    margin-bottom: var(--base-spacing-half) ;
  }

  &.error {
    .input--text {
      input {
        border-color: var(--danger-medium);
      }

      i {
        display: none;

        &.error {
          display: block;
        }
      }
    }

    .input-group__error {
      display: block;
    }
  }

  &.success {
    .input--text {
      i {
        &.success {
          display: block;
        }
      }
    }
  }
}

/**
  * Generic input error
*/

.input-group__error {
  @include text-small;
  color: var(--danger-medium);
  padding-top: var(--base-spacing-quarter);
  display: none;
}

.input__error-message {
  @include text-small;
  color: var(--danger-medium);
}


/**
  * Generic input
  *
  * The input component is the base for the following form components:
  *
  * .input--text
  * .input--radiobutton
  * .input--checkbox
  * .input--password
  * .input--select
  * .input--toggle
  */

.input {
  color: var(--grey-dark-800);
  display: block;
  position: relative;

  label {
    position: relative;
    color: var(--grey-dark-800);

    &:hover {
      cursor: pointer;
    }
  }

  input {
    display: none;
  }
}


/**
  * Shared input styles for:
  *
  * .input--radiobutton
  * .input--checkbox
  *
  * 1. To align label and input, input size is used as line-height.
  * 2. Input without label (e.g. used in data tables).
  * 3. Shared input style for the base shape (outer background).
  * 4. Disabled unchecked input state.
  * 5. Disabled checked input state.
  */

.input--checkbox,
.input--radiobutton {
  color: var(--grey-dark-800);
  div {
    position: relative;
    height: auto;
    line-height: $checkbox-radio-size; /* [1] */
    padding-left: calc( #{$checkbox-radio-size}  + 8px);
    transition: color $base-transition;
    
    &:empty { /* [2] */
      padding-left: 0;
      height: $checkbox-radio-size;
      width: $checkbox-radio-size;
    }
    
    &:before { /* [3] */
      background-color: var(--white);
      border: var(--base-border);
      content: '';
      height: $checkbox-radio-size;
      left: 0;
      position: absolute;
      width: $checkbox-radio-size;
    }

    &:hover {
      &:before {
        border-color: var(--primary-light);
        background: var(--primary-subtle)
      }

      &:disabled {
        border-color: var(--grey-light-150);
      }
    }

    &:focus-visible {
      outline:none;
    }
  }

  input {
    &:checked {
      + div {
        &:before {
          border-color: var(--primary-medium);
        }
      }
    }

    &:disabled { /* [4] */
      + div {
        color: var(--grey-medium-400);
        text-decoration: none;

        &:before {
          background-color: var(--grey-subtle-028);
        }
        
        &:hover, &:focus {
          &:before {
            border-color: var(--grey-light-150);
          }
        }
      }
    }
    
    &:disabled:checked { /* [5] */
      + div {
        &:before {
          border-color: var(--grey-light-150);
          background-color: var(--grey-subtle-028);
        }
      }
    }
  }
}


/**
  * Input inline
  *
  * Inputs are stacked vertically by default. This modifier takes
  * care of the horizontal alignment.
  */

.input--inline {
  display: inline-block;

  + .input--inline {
    margin-bottom: var(--base-spacing) ;
    margin-top: 0;

    &:nth-of-type(2) {
      margin-left: var(--base-spacing) ;
    }

    &:not(:last-of-type) {
      margin-right: var(--base-spacing) ;
    }
  }
}