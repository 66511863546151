/*------------------------------------*\
  Radio button
\*------------------------------------*/

$radio-height-after: 0.75rem;
$radio-width-after:0.25rem;
$radio-top-after: 0.25rem;;
$radio-left-after: 0.5rem;

$radio-left-after-checked: 0.25rem;
$radio-width-after-checked: 0.75rem;


/** Input radio button
  *
  * 1. Outer radio button circle.
  * 2. Inner radio button circle.
  * 3. Inner circle checked input state.
  * 4. Inner circle disabled checked input state.
  */

.input--radiobutton {
  div {
    &:before { /* [1] */
      border-radius: 0.625rem;
      transition: border-color $base-transition;
    }

    &:focus-visible:before {
      box-shadow: var(--base-focus-box-shadow);
    }

    &:after { /* [2] */
      background-color: transparent;
      border-radius: 0.5rem;
      content: '';
      height: $radio-height-after;
      left: $radio-left-after;
      position: absolute;
      top: $radio-top-after;
      width: $radio-width-after;
      transition: background-color $base-transition,
                  left $base-transition,
                  width $base-transition;
    }
  }

  input {
    &:checked {
      + div {
        &:after { /* [3] */
          background-color: var(--primary-medium);
          left: $radio-left-after-checked;
          width: $radio-width-after-checked;
        }
      }
    }

    &:disabled:checked {
      + div {
        &:after { /* [4] */
          background-color: var(--grey-light-150);
        }
      }
    }
  }
}
