/*------------------------------------*\
  Heading
\*------------------------------------*/

/**
  * H1
  */

.heading-hero {
  @include heading-hero;
}

.heading-xlarge {
  @include heading-x-large;
}


/**
  * H2
  */

.heading-large {
  @include heading-large;
}


/**
  * H3
  */

.heading-medium {
  @include heading-medium;
}


/**
  * H4
  */


.heading-small {
  @include heading-small;
}


/**
  * H5
  */

.heading-xsmall {
  @include heading-x-small;
}

/**
  * H6
  */
.heading-xxsmall {
  @include heading-xx-small;
}
