/**
  * Selector
  */

.input--selector {
  display: flex;

  label {
    display: block;
    margin-bottom: var(--base-spacing-quarter);
  }

  .input--selector__option__input {
    opacity: 0;
    position: absolute;
    display: inline-block;
  }

  .input--selector__option__label {
    background-color: var(--white);
    margin: var(--base-spacing-eighth) ;
    padding: var(--base-spacing-half)  var(--base-spacing) ;
    border: var(--base-border);
    color: var(--grey-medium-600);

    &:hover {
      background-color: var(--grey-subtle-028);
      border-color: var(--grey-light-150);

    }
  }

  .input--selector__option__input:focus-visible + .input--selector__option__label {
    outline: none;
    box-shadow: var(--base-focus-box-shadow);
    border-color: var(--primary-medium);
  }

  .input--selector__option__input:checked + .input--selector__option__label {
    background-color: var(--primary-medium);
    border-color: var(--primary-medium);
    color: var(--white);

    &:hover {
      background-color: var(--primary-dark);
      border-color: var(--primary-dark);
    }
  }

  .input--selector__option__input:disabled + .input--selector__option__label {
    background-color: var(--white);
    border-color: var(--grey-light-150);
    color: var(--grey-medium-400);
    cursor: not-allowed;
  }

  .input--selector__option__input:checked:disabled + .input--selector__option__label {
    background-color: var(--grey-light-150);
    border-color: var(--grey-light-150);
    color: var(--white)
  }
}

