/*------------------------------------*\
  Borders
\*------------------------------------*/

:root,
:before,
:after {
  --base-border-radius: 4px;
  --base-border: 1px solid var(--grey-light-150);
  --base-focus-box-shadow: 0 0 0 4px var(--primary-focus-transparent);
}