/*------------------------------------*\
  Spacing
\*------------------------------------*/

/**
  * Margins
  */

.margin { margin: var(--base-spacing) !important; }
.margin-top { margin-top: var(--base-spacing) !important; }
.margin-right { margin-right: var(--base-spacing) !important; }
.margin-bottom { margin-bottom: var(--base-spacing) !important; }
.margin-left { margin-left: var(--base-spacing) !important; }
.margin-inline { margin-inline: var(--base-spacing) !important; }
.margin-block { margin-block: var(--base-spacing) !important; }


/**
  * Paddings
  */

.padding { padding: var(--base-spacing) !important; }
.padding-top { padding-top: var(--base-spacing) !important; }
.padding-right { padding-right: var(--base-spacing) !important; }
.padding-bottom { padding-bottom: var(--base-spacing) !important; }
.padding-left { padding-left: var(--base-spacing) !important; }
.padding-inline { padding-inline: var(--base-spacing) !important; }
.padding-block { padding-block: var(--base-spacing) !important; }
