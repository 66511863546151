/*------------------------------------*\
  Sidenav
\*------------------------------------*/

/**
  * Left sidenav
  */

.sidenav {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: transparent;
  overflow-x: hidden;
  padding-top: var(--base-spacing) ;
  padding-left: var(--base-spacing-half) ;
  max-width: 150px;

  a {
    font-weight: var(--font-weight-light);
    color: var(--grey-medium-400);
    text-decoration: none;
    display: block;
    padding: var(--base-spacing-half) ;

    &:hover {
      color: var(--primary-medium);
    }
  }

  .sidenav__dropdown__header {
    @include heading-small;
    text-decoration: none;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
    padding: var(--base-spacing-quarter)  var(--base-spacing-half) ;
    margin-top: var(--base-spacing-half) ;

    &:hover {
      color: var(--primary-medium);
    }
  }
}

.sidenav__dropdown {
  border-bottom: 1px solid var(--grey-subtle-028);

  .active {
    color: var(--primary-medium) !important;
  }
}

.sidenav__dropdown__content {
  display: none;
}

.sidenav-container {
  margin-left: 150px; /* Same as the width of the sidenav */
  padding: 20px;
}