/*------------------------------------*\
  Accordion
\*------------------------------------*/

/**
  * Component
  */

.accordion {
  position: relative;

  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    display: none;
    z-index: -1;
  }
    
  input[type="radio"]:checked,
  input[type="checkbox"]:checked {
    ~ .accordion__header .show-less,
    ~ .accordion__content {
      display: block;
    }
    ~ .accordion__header .show-more {
      display: none;
    }
  }
}

.accordion__header {
  @include text-small;
  display: block;
  position: relative;
  width: max-content;
  height: 2.5rem;
  margin: 0 auto;
  padding: var(--base-spacing-half) var(--base-spacing);
  text-align: center;
  background-color: var(--grey-subtle-028);
  border-radius: var(--base-spacing-quarter) ;
  cursor: pointer;
  border: 1px solid transparent;

  &:focus-visible {
    outline: none;
    box-shadow: var(--base-focus-box-shadow);
    border-color: var(--primary-medium);
  }
  &:hover {
    color: var(--grey-dark-800);
  }

  i {
    font-size: $icon-size-small;
    margin-right: var(--base-spacing-half) ;
  }
}

.accordion__background-line {
  width: 100%;
  height: 1px;
  margin-bottom: 0;
  background-color: var(--grey-light-150);
  position: absolute;
  bottom: 1.25rem;
  z-index: -1;
}

.accordion__header .show-less, 
.accordion__content {
  display: none;
}

/**
  * Modifiers
  */

.accordion--show-md {
  @include media-breakpoint-up(md) {

    .accordion__content {
      display: block;
      margin: 0;
    }

    .accordion__header {
      @include media-breakpoint-up(md) {
        pointer-events: none;
      }

      &:before {
        display: none;
      }
    }
  }
}
