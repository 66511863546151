/*------------------------------------*\
  Grid
\*------------------------------------*/

/**
  * Breakpoints
  */
$grid-width-xs:  0;
$grid-width-sm:  576px;
$grid-width-md:  768px;
$grid-width-lg:  1024px;
$grid-width-xl:  1200px;

  $grid-breakpoints: (
  xs: $grid-width-xs,
  sm: $grid-width-sm,
  md: $grid-width-md,
  lg: $grid-width-lg,
  xl: $grid-width-xl
);


/**
  * Container
  */

$container-max-widths: (
  xs: 100%,
  sm: 100%,
  md: 100%,
  lg: 960px,
  xl: 1140px
);


$container-margins: (
  xs: var(--base-spacing) ,
  sm: var(--base-spacing) ,
  md: var(--base-spacing) ,
  lg: auto,
  xl: auto
);


/**
  * Grid & gutter
  */

$grid-columns: 12;


$grid-gutter-widths: (
  xs: var(--base-spacing) ,
  sm: var(--base-spacing) ,
  md: var(--base-spacing) ,
  lg: var(--base-spacing-oneandhalf) ,
  xl: var(--base-spacing-oneandhalf)
);
