/*------------------------------------*\
  Footer
\*------------------------------------*/

footer {
  background: var(--grey-dark-800);
  padding: var(--base-spacing-double);
  color: var(--white);

  .heading-xsmall,
  .heading-small,
  .link {
    color: var(--white);
  }

  .footer__container {
    @include media-breakpoint-up(lg) {
      display:flex;
      justify-content: space-between;
    }
  }
  .link {
    text-decoration: underline;
    &:hover {
      color: var(--primary-light);
    }
  }
}

.footer__left {
  text-align: left;
  @include media-breakpoint-up(md) {
    text-align: initial;
    padding-right: var(--base-spacing) ;
  }

  h5 {
    padding-bottom: var(--base-spacing-half) ;
  }
}

.footer__right {
  display:flex;
  flex-direction: column;
  row-gap: var(--base-spacing-oneandhalf) ;
  justify-content: space-between;
  padding-top: var(--base-spacing-double) ;
  color: var(--grey-light-150);

  @include media-breakpoint-up(md) {
    flex-direction: row;
    row-gap: var(--base-spacing-oneandhalf) ;
    align-items: flex-end;
  }

  .link {
    display: block;
    @include media-breakpoint-up(md) {
      display: inline-block;
      margin-left: var(--base-spacing) ;
    }
  }
}