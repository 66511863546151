/*------------------------------------*\
  Hero
\*------------------------------------*/

.hero {
  position: relative;
  display: flow-root;
  overflow: hidden;
  width: 100%;

}

.hero__photo {
  position: relative;
  width: 100%;
  min-height: 64px;

  img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -1;
    height: 100%;
    position: absolute;
  }
}

.hero__content {
  color: white;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  position: absolute;

  h2 {
    font-weight: var(--font-weight-semi-bold);
  }

  h3, .hero__buttons {
    margin-top: var(--base-spacing-double)
  }

  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

a.text-ccv-white {
  text-decoration: underline;
}

/**
  * Generic gradients
  */

.hero__gradient {
  background-image: linear-gradient(110deg, #009fdf 30%, rgba(0, 159, 223, 0) 80%, transparent);
}