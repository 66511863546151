/*------------------------------------*\
  Text
\*------------------------------------*/

/**
  * Alignment
  */

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}


/**
  * Colors
  */

.text-ccv-blue {
  color: var(--primary-medium) !important;
}

.text-ccv-dark-blue {
  color: var(--primary-dark) !important;
}

.text-ccv-orange {
  color: var(--warning-medium) !important;
}

.text-ccv-green {
  color: var(--success-medium) !important;
}

.text-ccv-red {
  color: var(--danger-medium) !important;
}

.text-ccv-dark-grey {
  color: var(--grey-dark-800);
}

.text-ccv-medium-grey {
  color: var(--grey-medium-600) !important;
}

.text-ccv-grey {
  color: var(--grey-medium-400) !important;
}

.text-ccv-white {
  color: var(--white) !important;
}
