/*------------------------------------*\
  Header
\*------------------------------------*/

// TODO:
// 1. Validate header design with UX.
// 2. Cleanup code.

$bounce: cubic-bezier(.25, 1.7, .35, .8);

$headerheight: 64px;
$borderheight: 4px;

.header {
  height: $headerheight;
  width: 100%;
  background: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  border-bottom: var(--base-border);
  transition: border-color $base-transition;

  &.active {
    border-bottom-color: var(--white);
    position: fixed;
  }

  @include media-breakpoint-up(lg) {
    position: fixed;

    &.active {
      position: fixed;
    }
  }

  .container {
    height: 100%;
  }
}

.header__content {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.header__icons a {
  color:var(--primary-dark);
}

.nav {
  margin: 0;
  flex-direction: row;
  height: $headerheight;
  list-style: none;
  padding: 0;
  flex-grow: 1;
  display: none;

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.nav__item {
  height: $headerheight;
  margin-left: var(--base-spacing-double) ;
}

.nav__link {
  position: relative;
  text-decoration: none;
  color: var(--grey-dark-800);
  height: $headerheight;
  padding: 0;
  line-height: $headerheight - $borderheight;
  font-size: 1.125rem;
  display: block;
  border-top: $borderheight solid var(--white);
  transition: color $base-transition,
  border-color $base-transition;

  .icon {
    transition: transform $base-transition,
    fill $base-transition;
  }

  &:hover,
  &.active {
    color: var(--primary-medium);

    .icon {
      fill: var(--primary-medium);
    }
  }

  &.active {
    border-color: var(--primary-medium);

    .icon {
      transform: rotate(180deg);
    }
  }

  .icon {
    vertical-align: text-top;
  }
}

.keyboard_arrow_down {
  height: $headerheight;

  a {
    position: relative;
    height: $headerheight;
    line-height: $headerheight - ($borderheight * 2);
    text-decoration: none;
    margin-left: var(--base-spacing) ;
    border-top: $borderheight solid var(--white);

    .icon {
      vertical-align: middle;
      transition: fill $base-transition;
    }

    .icon--cross {
      display: none;
    }

    &:hover {
      .icon {
        fill: var(--primary-medium);
      }
    }

    &.active {
      border-color: var(--primary-medium);

      .icon--cross {
        display: inline-block;
      }

      .icon--hamburger {
        display: none;
      }

      .icon {
        fill: var(--primary-medium);
      }
    }
  }
}

.menu {
  @include media-breakpoint-up(lg) {
    display: none;
  }

  .show {
    display: inline-block !important;
  }

  .hide {
    display: none;
  }

  i:nth-child(2) {
    display: none;
  }
}

.flyout {
  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
    padding: 0 var(--base-spacing-oneandhalf) ;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    background: var(--white);
    position: fixed;
    top: -100%;
    width: 100%;
    left: 0;

  }

  .col-md-3 {
    padding-top: 0;
    opacity: 0;
    padding-bottom: var(--base-spacing-double) ;

    transition: opacity 0.2s ease-out 0.2s,
    padding-top 0.2s ease-out 0.2s;

    + .col-md-3 {
      transition-delay: 0.25s;

      + .col-md-3 {
        transition-delay: 0.3s;

        + .col-md-3 {
          transition-delay: 0.35s;
        }
      }
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
    top: $headerheight;
    padding-bottom: 0;
    box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;

    .col-md-3 {
      opacity: 1;
      padding-top: var(--base-spacing-double) ;
    }
  }

  .heading-medium {
    margin-bottom: var(--base-spacing-double) ;

    a {
      display: inline-block;
      text-decoration: none;
      color: var(--grey-dark-800);

      &:hover {
        color: var(--primary-medium);
      }
    }
  }

  .btn {
    margin-bottom: var(--base-spacing) ;
  }
}

.flyout-mobile {
  position: fixed;
  width: 100%;
  padding: 0;
  top: -100%;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  background: var(--white);

  transition: all $base-transition;

  &.active {
    top: $headerheight;
    z-index: 1;
    visibility: visible;
    opacity: 1;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.nav-mobile {
  padding: 0;
  margin: 0;

  ul {
    display: none;
  }

  li {
    list-style: none;

    .nav__link {
      padding: var(--base-spacing);
      padding-left: var(--base-spacing-threequarters) ;
      display: block;
      color: var(--primary-dark);
      font-size: 1.375rem;
      text-decoration: none;
      position: relative;
      border-left: $borderheight solid var(--white);
      border-top: $borderheight solid var(--white);
      padding-top: 0;

      &:hover,
      &.active {
        color: var(--primary-medium);
        border-color: var(--primary-medium);
        border-top: $borderheight solid var(--white);
        padding-left: 16px;

        .icon {
          fill: var(--primary-medium);
        }
      }

      &.active {
        .icon {
          transform: rotate(180deg);
        }

        ~ ul {
          border-left: $borderheight solid var(--primary-subtle-transparent);

          ul {
            border-left: 0;
          }
        }
      }

      .icon {
        position: absolute;
        right: var(--base-spacing) + $borderheight;
        top: 10px;
        transition: transform $base-transition;
      }
    }

    .active {
      display: block;
      border-top: var(--base-border);
      padding: 0;
      margin: 0;

      li {
        border-bottom: var(--base-border);

        a {
          font-weight: var(--font-weight-light);
          color: var(--grey-dark-800);
          font-size: 1.25rem;
          text-decoration: none;
          border-left: 0;
          padding: var(--base-spacing) ;

          .icon {
            fill: var(--grey-medium-600);
          }
        }

        ul {
          padding: var(--base-spacing);
          padding-top: 0;
          margin: 0;
          border: none;

          li {
            border: none;

            a {
              font-size: 1rem;
              color: var(--primary-dark);
              border-left: 0;
              padding: var(--base-spacing) ;

              &:hover {
                background: var(--primary-subtle-transparent);
                border-radius: var(--base-border-radius);
              }
            }
          }
        }
      }
    }
  }
}
