/*------------------------------------*\
  Message
\*------------------------------------*/

@mixin text-and-box-shadow-color($color) {
  box-shadow: inset 2px 0 0 $color;
  color: $color;
}

.message {
  @include text-and-box-shadow-color(var(--grey-dark-800));
  padding: var(--base-spacing-oneandhalf);
  background: var(--grey-subtle-028);
  border-radius: var(--base-border-radius);
}

.message--info {
  @include text-and-box-shadow-color(var(--primary-medium));
  background: var(--primary-subtle);
}

.message--alert {
  @include text-and-box-shadow-color(var(--warning-medium));
  background: var(--warning-subtle);
}

.message--success {
  @include text-and-box-shadow-color(var(--success-medium));
  background: var(--success-subtle);
}

.message--error {
  @include text-and-box-shadow-color(var(--danger-medium));
  background: var(--danger-subtle);
}