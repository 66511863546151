/*------------------------------------*\
  Table
\*------------------------------------*/

/**
  * Basic table
  */

.table {
  background: var(--white);
  border-collapse: collapse;
  border-spacing: 0;
  border: var(--base-border);
  font-size: var(--base-font-size);
  position: relative;
  width: 100%;
  
  th,
  td {
    &:last-of-type {
      border-right: var(--base-border);
    }

    .icon {
      fill: var(--grey-medium-600);
      margin-top: -6px;
      margin-bottom: -6px;
    }
  }

  th {

    a {
      color: var(--primary-medium);
    }
  }

  th,
  td {
    border-bottom: var(--base-border);
    padding: var(--base-spacing) ;
    transition: color $base-transition;
  }

  tbody {
    tr {
      transition: background-color $base-transition;

      &:hover {
        background-color: var(--grey-subtle-028);
        cursor: pointer;

        td {
          color: var(--grey-dark-800);
        }
      }
    }
  }
}


/**
  * Table heading
  */

.table__heading {
  th {
    border-bottom: 0;
    padding-bottom: 0;
  }
}


/**
  * Table spacing
  */

.table--spacing-half {
  th,
  td {
    padding-top: var(--base-spacing-half) ;
    padding-bottom: var(--base-spacing-half) ;
  }
}

.table--spacing-double {
  th,
  td {
    padding-top: var(--base-spacing-double) ;
    padding-bottom: var(--base-spacing-double) ;
  }
}
