/*------------------------------------*\
  Select
\*------------------------------------*/

/**
  * Base select
  *
  * 1. Base input select label, used as total clickable element.
  * 2. As the content collapses, the bottom border-radius is zeroed.
  * 3. To indicate the opened state, the triangle icon is rotated.
  * 4. height: auto; can not be transitioned, but a dynamic height is
       applicable due to the unknown content. Therefore max-height is
       used instead.
  * 5. Select disabled input state.
  */


$select-height: 3rem;

.input--select {
  input {
    height:0;
    width: 0;
    display:block;
    margin: 0;
    -moz-appearance: none;
  }

  input + div { /* [1] */
    @include text-medium;
    border: var(--base-border);
    border-radius: var(--base-border-radius);
    height: $select-height;
    padding: var(--base-spacing-threequarters)  var(--base-spacing) ;
    background-color: var(--white);
    position: relative;
    width: 100%;

    &:hover,
    &:focus {
      border-color: var(--primary-light);
      outline: none;

      .icon {
        fill: var(--grey-dark-800);
      }
    }

    &:focus-visible {
      box-shadow: var(--base-focus-box-shadow);
    }

    .input--select__default-label {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 1.5rem);
    }

    .icon {
      position: absolute;
      fill: var(--grey-medium-400);
      top: calc(50% - 0.75rem);
      right: 0.75rem;
      transition: transform $base-transition,
                  fill $base-transition;
    }
  }

  &:focus {
    outline: none;
  }

  input {
    &:checked {
      + div { /* [2] */
        border: var(--base-border);
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;

        > .icon { /* [3] */
          transform: rotate(180deg);
          color: var(--primary-medium);
        }

        .input--select__content {
          max-height: 210px; /* [4] */
          display: inherit;
        }

        &:after {
          display:block;
          content: "";
          height: 0;
          border-bottom: var(--base-border);
          margin-top: -1px;
          width: 100%;
        }
      }
    }

    &:disabled {
      + div { /* [5] */
        border-color: var(--grey-subtle-028-transparent);
        background-color: var(--grey-subtle-028);
        color: var(--grey-medium-400);

        &:hover {
          .icon {
            fill: var(--grey-medium-400);
          }
        }
      }
    }

    &.error {
      + div {
        border-color: var(--danger-medium);

        .input--select__content {
          border-color: var(--danger-medium);
        }
      }
    }
  }
}


/**
  * Select collapsible content
  *
  * 1. Because the select content is boxed inside the label and has a
  *    double 1px border, calc is used to expand the size of the collapse.
  */

.input--select__content {
  background: var(--white);
  border-radius: var(--base-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: var(--base-border);
  border-top: 0;
  left: -1px;
  list-style: none;
  max-height: 0;
  display:none;
  overflow-y: auto;
  position: absolute;
  top: 3rem;
  width: calc(100% + 2px); /* [1] */
  z-index: 10;
  transition: border-color $base-transition,
              opacity $base-transition,
              max-height $base-transition;

  .input--search {
    padding: 0 var(--base-spacing-half)  var(--base-spacing-half) ;
  }
}


/**
  * Select linklist
  *
  * 1. Typeahead searchresults are supported. This indicate
  *    the characters which are being typed on user input.
  */

.input--select__linklist {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: var(--base-spacing-half) ;

  .input--select__option {
    display: block;
    padding: var(--base-spacing-threequarters)  var(--base-spacing) ;
    text-decoration: none;
    background-color: var(--white);
    border-radius: var(--base-border-radius);
    color: var(--grey-dark-800);
    transition: background-color $base-transition,
    color $base-transition;

    &:hover, &:focus-visible {
      background-color: var(--primary-subtle-transparent);
      outline: none;
    }

    .typed { /* [1] */
      font-weight: var(--font-weight-semi-bold);
    }

    &.selected {
      color: var(--primary-medium);
    }
  }

  .input--select__multiselect-option {
    display: grid;
    justify-content: flex-start;
    align-items: center;
    grid-template-columns: 1.25rem 1fr;
    column-gap: var(--base-spacing-half);

    &.selected {
      .input--select__option-checkbox {
        border-color: var(--primary-medium);
        background-color: var(--primary-medium);

       i {
         display:block;
       }
      }
    }
    &:hover,
    &:focus-visible {
      .input--select__option-checkbox {
        border-color: var(--primary-medium);
      }
    }
  }
}


/**
  * Select checkbox list
  *
  * 1. As a checkbox list is nested inside another input component,
  *    additional styling is needed to accommodate the design.
  */

.input--select__checkboxlist {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: var(--base-spacing-half) ;

  li {
    padding: 0;
    margin: 0 var(--base-spacing-half) ;

    .input--checkbox { /* [1] */
      div {
        background-color: var(--white);
        display: block;
        padding-top: var(--base-spacing-threequarters) ;
        padding-bottom: var(--base-spacing-threequarters) ;
        padding-left: calc($checkbox-radio-size + var(--base-spacing));
        border: none;
        height: auto;
        transition: background-color $base-transition;

        &:before {
          left: var(--base-spacing-half) ;
        }

        &:after {
          left: $checkbox-left-after + var(--base-spacing-half) ;
          top: $checkbox-top-after + var(--base-spacing-half) ;
        }

        &:hover {
          background-color: var(--primary-subtle-transparent);
          text-decoration: none;
        }
      }

      input:checked {
        + div {
          &:after {
            left: $checkbox-left-after-checked + var(--base-spacing-half) ;
            top: $checkbox-top-after-checked + var(--base-spacing-half) ;
          }
        }
      }
    }
  }

  + .input--select__checkboxlist {
    > li:first-of-type {
      padding-top: var(--base-spacing-half) ;
      border-top: var(--base-border);
      border-color: var(--grey-light-150);
    }
  }
}

.input--select__option-checkbox {
  width:1.25rem;
  height:1.25rem;
  border-radius: var(--base-border-radius);
  border: var(--base-border);

  i {
    font-size: 1.125rem;
    color: var(--white);
    display:none;
    font-variation-settings: 'opsz' 48
  }
}