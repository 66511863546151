/*------------------------------------*\
  Tags
\*------------------------------------*/

.tag-label {
  background: var(--grey-subtle-028);
  border: 0;
  border-radius: var(--base-border-radius);
  color: var(--grey-dark-800);
  font-size: 0.875rem;
  padding: var(--base-spacing-half)  var(--base-spacing) ;
  cursor: pointer;

  &:hover {
    background:  var(--grey-light-150);
  }
}
