/*------------------------------------*\
  Button
\*------------------------------------*/

$base-button-height: 3.125rem;

/**
  * Base button
  */

  .btn {
    @include text-medium;
    font-weight: var(--font-weight-semi-bold);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: calc(#{$base-button-height} / 2);
    cursor: pointer;
    height: $base-button-height;
    overflow: hidden;
    padding: var(--base-spacing-threequarters)  var(--base-spacing-twoandhalf);
    text-align: center;
    white-space: nowrap;
    width: 100%;
  
    @include media-breakpoint-up(sm) {
      vertical-align: middle;
      display: inline-block;
      margin: 0;
      width: auto;
    }
  

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }

    &:disabled:hover {
      cursor: not-allowed;
      text-decoration: none;
    }

  &:focus-visible {
    box-shadow: var(--base-focus-box-shadow);
  }
  
  i {
    margin-right: var(--base-spacing-half) ;
  }
}


/**
  * Button Primary
  */

.btn--primary {
  background-color: var(--primary-medium);
  color: var(--white);
  transition: background-color $base-transition;
  
  &:hover {
    background-color: var(--primary-dark);
  }

  &:disabled {
    color: var(--grey-light-150);
    border-color: var(--grey-light-150);
    background-color: var(--grey-subtle-028);
  }
}


/**
  * Button secondary
  */

.btn--secondary {
  border-color: var(--primary-medium);
  color: var(--primary-medium);
  transition: border-color $base-transition,
              color $base-transition;
  
  &:hover {
    background: var(--primary-subtle-transparent);
    text-decoration: none;
  }

  &:disabled {
    color: var(--grey-light-150);
    border-color: var(--grey-light-150);
    background-color: var(--white);
  }
}

/**
  * Button tertiary
  */

.btn--tertiary {
  font-weight: var(--font-weight-semi-bold);
  color: var(--grey-medium-600);
  padding: var(--base-spacing-threequarters)  var(--base-spacing-quarter) ;
  transition: color $base-transition;
  border-radius: var(--base-border-radius);

  &:hover,
  &:focus-visible {
    color: var(--primary-medium);
  }

  &:focus-visible {
    border-color: var(--primary-medium)
  }

  &:disabled {
    color: var(--grey-light-150);
    background-color: transparent;
  }
}


/**
  * Button small
  */

.btn--small {
  @include text-small;
  font-weight: var(--font-weight-semi-bold);
  height: calc(#{$base-button-height} - 0.625rem);
  padding: var(--base-spacing-half)  var(--base-spacing-double) ;
}


.btn--tertiary.btn--small {
  padding: var(--base-spacing-half)  var(--base-spacing-quarter) ;
}

/**
  * Button block
  */

.btn--block {
  width: 100%;
  display: block;

  + .btn {
    margin-left: 0;
    margin-top: var(--base-spacing) ;
  }
}
