/*------------------------------------*\
  Fonts
\*------------------------------------*/
//Fonts are imported in main.css, to make sure they are in the same relative position after deployment

:root,
:before,
:after {
  --base-font-family: "Source Sans 3", "Arial", sans-serif;
  --base-font-size: 1.0625rem;
  --base-line-height: 162%;
  --font-weight-light: 300;
  --base-font-weight: 370;
  --font-weight-semi-bold: 520;
  --font-weight-bold: 650;
}