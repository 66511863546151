/*------------------------------------*\
  Link
\*------------------------------------*/

/**
  * Link
  */


button.link-button {
  all: unset;
}

.link,
button.link-button {

  color: var(--primary-medium);
  font-size: inherit;
  cursor: pointer;

  &:hover {
    color: var(--primary-dark);
  }

  &:focus-visible {
    outline:none;
    box-shadow: var(--base-focus-box-shadow);
  }
}


