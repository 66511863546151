/*------------------------------------*\
  Stepper
\*------------------------------------*/

$stepper-size: 48px;


/**
  * Base stepper
  */

.stepper {
  display: inline-flex;
}


/**
  * Base stepper elements
  */

.stepper__minus,
.stepper__plus,
.stepper__number {
  border: var(--base-border);
  height: $stepper-size;
  width: $stepper-size;
  outline: none;
  background-color: var(--white);
}


/**
  * Stepper minus/plus buttons
  */

.stepper__minus,
.stepper__plus {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border-color $base-transition,
              background-color $base-transition;

  i {
    color: var(--grey-dark-800);
    transition: color $base-transition;
  }

  &:hover,
  &:focus {
    border-color: var(--primary-medium);
    outline: none;
    z-index: 2;
    
    i {
        color: var(--primary-medium);
      }
    }
  &:focus-visible {
    box-shadow: var(--base-focus-box-shadow);
  }
  
  &:active {
    background-color:var(--primary-medium);
    
    i {
      color:var(--white);
    }
  }

  &.disabled,
  &:disabled {
    background-color: var(--grey-light-150);
    pointer-events: none;

    i {
      color: var(--grey-medium-400);
    }
  }
}

.stepper__minus {
  border-top-left-radius: var(--base-border-radius);
  border-bottom-left-radius: var(--base-border-radius);
}

.stepper__plus {
  border-top-right-radius: var(--base-border-radius);
  border-bottom-right-radius: var(--base-border-radius);
}


/**
  * Stepper input number.
  *
  * 1. Hide Webkit's default inner input minus/plus buttons.
  *    Keyboard support (up/down keys) is still enabled.
  */

.stepper__number {
  @include text-medium;
  height: 48px;
  padding: var(--base-spacing-threequarters)  0;
  border: var(--base-border);
  margin: 0 -1px;
  text-align: center;
  appearance: textfield;

  &:focus-visible {
    border-color: var(--primary-medium);
    z-index: 2;
    box-shadow: var(--base-focus-box-shadow);
  }

  &.error {
    border-color: var(--danger-medium);
    z-index: 1;
  }

  &::-webkit-inner-spin-button, /* [1] */
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
