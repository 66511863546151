.input.input--datepicker {
  display: flex;
  flex-wrap: wrap;
}

.input--datepicker__day,
.input--datepicker__year {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: 25%;
  }
}

.input--datepicker__month,
.input--datepicker__year {
  margin-top: 10px;
  margin-left: 0;

  @include media-breakpoint-up(sm) {
    margin-top: 0;
    margin-left: var(--base-spacing-half) ;
  }
}

.input--datepicker__month {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: calc(50% - 16px);
  }

  .input--select__hidden-checkbox {
    display: none;
  }
}
