.tabs {
  display: flex;
  justify-content: flex-start;

   input {
    opacity: 0;
     width:0;
     height:0;
     margin:0;

    &:focus-visible + label {
      //use inset box shadow here otherwise part of it isn't visible next to other tabs
      box-shadow: inset 0 0 0 4px var(--primary-focus-transparent);
    }

    &:checked + label {
      color: var(--grey-dark-800);
      background: var(--white);
      border-bottom: 0;
    }
  }

  label:first-of-type {
    border-top-left-radius: var(--base-border-radius);
  }
  label:last-of-type {
    border-top-right-radius: var(--base-border-radius);
  }

  > label {
    @include text-medium;
    font-weight: var(--font-weight-semi-bold);
    background: var(--grey-subtle-028);
    border: var(--base-border);
    display: block;
    min-width:fit-content;
    padding: var(--base-spacing-half)  var(--base-spacing-twoandhalf) ;
    cursor: pointer;
    transition: background-color .3s;
    border-collapse: collapse;

    &.unread {
      position: relative;

      &::before {
        content: "";
        width: 6px;
        height: 6px;
        background-color: var(--danger-medium);
        border-radius: 50%;
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }

  > hr {
    width:100%;
    margin: 0;
    padding:0;
    border-bottom: var(--base-border);
    border-top:0;
  }
}

.tabs.tabs--subtle  {
  input:checked + label {
    background-color: transparent;
    border-bottom: 1px solid var(--primary-medium);
    color: var(--primary-medium);
  }


  > label {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid var(--grey-subtle-028-transparent);
    padding: var(--base-spacing-threequarters) ;

    &:hover {
      border-bottom: 1px solid var(--primary-medium);
      color: var(--primary-medium);
    }

    &.unread::before {
      width: 5px;
      height: 5px;
      top: 12px;
    }
  }

  > hr {
    border-bottom: 1px solid var(--grey-subtle-028-transparent);
  }
}


