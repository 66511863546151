/*------------------------------------*\
  List
\*------------------------------------*/

/**
  * Component
  */

.list {
  width: 100%;
  padding: 0;
  padding-left: var(--base-spacing) ;
}

.list__item {
  padding: 0;
  list-style: none;
  position: relative;

  &:not(:last-child) {
    margin-bottom: var(--base-spacing-quarter) ;
  }
}

.list__item__link {
  display: inline-block;
  
  &:hover {
    color: var(--primary-medium);
    text-decoration: underline;
  }
}


/**
  * List number
  */

.list--number {
  counter-reset:list__item;
  padding-left: var(--base-spacing-oneandhalf) ;

  .list__item {

    &:before {
      content: counter(list__item);
      counter-increment: list__item;
      position: absolute;
      left: calc(0  - var(--base-spacing));
      color: var(--support-green);
    }
  }
}


/**
  * List bullet
  */

.list--bullet {
  padding-left: var(--base-spacing-oneandhalf) ;

  .list__item {
    list-style: disc;
  }
}


/**
  * List line
  */

.list--line {
  border-top: var(--base-border);
  padding-left: 0;

  .list__item {
    border-bottom: var(--base-border);
    text-align: center;
    padding: var(--base-spacing) ;
    margin: 0;

    * {
      padding: 0;
      margin: 0;
    }
  }
}


/**
  * List label
  */

.list--label {
  .list__item {
    margin-bottom: var(--base-spacing) ;
  }

  .list__item__label {
    font-weight: var(--font-weight-semi-bold);
    color: var(--grey-dark-800);
    font-size: 1.125rem;
  }

  .list__item__content {
    color: var(--grey-medium-400);
    font-size: 1rem;
  }
}


/**
  * List icon
  */

.list--icon {
  padding-left: var(--base-spacing-oneandhalf) ;

  .icon {
    position: absolute;
    left: calc (0 - var(--base-spacing-oneandhalf));
    fill: var(--support-green);
  }
}
