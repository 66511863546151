/*------------------------------------*\
  Text
\*------------------------------------*/

.text-large {
  @include text-large;
}

.text-medium {
  @include text-medium;
}

.text-small {
  @include text-small;
}

.text-x-small {
  @include text-xsmall;
}

.text-regular {
  font-weight: var(--base-font-weight);
}

.text--semi-bold {
  font-weight: var(--font-weight-semi-bold);
}

.text--bold {
  font-weight: var(--font-weight-bold);
}

.text--light {
  font-weight: var(--font-weight-light);
}

.text--link {
  text-decoration: underline;
}
