/**
  * Search
  */

.input--search {
    margin: 0;

    .input--search__input {
        padding-right: var(--base-spacing-twoandhalf) ;
    }

    .icon {
        display: inherit;
        pointer-events: none;
        left: auto;
        right: var(--base-spacing-half) ;
    }

    .input--search__input:focus + .icon {
        color: var(--primary-medium);
    }
}