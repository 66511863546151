/*------------------------------------*\
  Card List
\*------------------------------------*/

/**
  * Component
  */

$max-image-height: 48px; 
$max-image-width: 96px;

.card-list {
  padding-left: 0;
  list-style-type: none;
}

.card-list__item {
  padding: var(--base-spacing-oneandhalf) ;
  border-radius: var(--base-border-radius);
  margin-bottom: var(--base-spacing) ;
  box-shadow: 0 1px 2px var(--box-shadow-bottom), 1px -1px 0 var(--white);


  display: flex;
  justify-content: space-between;

  .input--radiobutton {
    align-self: center;
  }

  img {
    max-width: $max-image-width;
    max-height: $max-image-height;
    margin-left: var(--base-spacing) ;
    align-self: center;
  }
  p {
    padding-left: calc(#{$checkbox-radio-size} + var(--base-spacing-half));
    margin-bottom: 0;
  }
}


/**
  * Collapsible card list
  */

.card-list--collapsible {
  .card-list__item {
    display: block;

    .card-list__item-body {
      display: none;
    }

    &.selected {
      .card-list__item-header {
        margin-bottom: var(--base-spacing-oneandhalf) ;
      }

      .card-list__item-body {
        display: block;
      }
    }
  }
}


.card-list__item-header {
  display: flex;
  justify-content: space-between;
}

.card-list__item-body {
  margin-top: var(--base-spacing-oneandhalf) ;
  padding-top: var(--base-spacing-oneandhalf) ;
  border-top: var(--base-border);
  display: block;

  .input--select {
    margin-bottom: var(--base-spacing-oneandhalf) ;
  }
}
