@use "sass:math";
/*------------------------------------*\
  Grid
\*------------------------------------*/

/**
  * Grid columns
  *
  * 1. Prevent columns from collapsing when empty.
  * 2. Allow columns to stretch full width below their breakpoints.
  * 3. Provide basic `.col-{bp}` classes for equal-width flexbox columns.
  * 4. Reset earlier grid tiers.
  */

@mixin make-grid-columns($columns: $grid-columns, $gutter-widths: $grid-gutter-widths, $breakpoints: $grid-breakpoints) {
  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px; /* [1] */

    @each $breakpoint, $gutter-width in $grid-gutter-widths {
      @include media-breakpoint-up($breakpoint, $breakpoints) {
        padding-right: calc(#{$gutter-width} / 2);
        padding-left:  calc(#{$gutter-width} / 2);
      }
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @for $i from 1 through $columns { /* [2] */
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      .col#{$infix} { /* [3] */
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none; /* [4] */
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      @for $i from 1 through $columns {
        .order#{$infix}-#{$i} {
          order: $i;
        }
      }
    }
  }
}


/**
  * Container
  */

@mixin make-container($breakpoints: $grid-breakpoints, $gutter-widths: $grid-gutter-widths, $margins: $container-margins) {

  @each $breakpoint, $margin in $margins {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      margin-right: ($margin);
      margin-left:  ($margin);
    }
  }

  @each $breakpoint, $gutter-width in $gutter-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      //padding-right: math.div($gutter-width, 2);
      //padding-left:  math.div($gutter-width, 2);
    }
  }
}

/**
  * Container max-width
  */

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

/**
  * Row
  */

@mixin make-row($breakpoints: $grid-breakpoints, $gutter-widths: $grid-gutter-widths) {
  display: flex;
  flex-wrap: wrap;
  
  @each $breakpoint, $gutter-width in $grid-gutter-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      margin-right: calc(#{$gutter-width} / -2);
      margin-left:  calc(#{$gutter-width} / -2);
    }
  }
}

/**
  * Grid columns
  *
  * Prevent columns from becoming too narrow when at smaller grid tiers by
  * always setting `width: 100%;`. This works because we use `flex` values
  * later on to override this initial width.
  */

@mixin make-col-ready() {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: var(--base-spacing-half);
  padding-left:  var(--base-spacing-half)
}

/**
  * Grid columns
  *
  * Add a `max-width` to ensure content within each column does not blow out
  * the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  * do not appear to require this.
  */

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage(math.div($size, $columns));
  max-width: percentage(math.div($size, $columns));
}
