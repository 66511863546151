/*------------------------------------*\
  Colors
\*------------------------------------*/

:root,
:before,
:after {
  --primary-subtle-transparent: #0395FF0D;
  --primary-subtle: #F2FAFF;
  --primary-focus-transparent: #0395FF26;
  --primary-light: #42B0FF;
  --primary-medium: #0395FF;
  --primary-dark: #0376DE;

  --white: #FFFFFF;
  --grey-subtle-028-transparent: #03091707;
  --grey-subtle-028: #F8F8F9;
  --grey-light-150: #D9DADC;
  --grey-medium-400: #9A9DA2;
  --grey-medium-600: #686C74;
  --grey-dark-800: #30343B;
  --black: #000000;

  --warning-subtle-transparent: #FF6A000D;
  --warning-subtle: #FFF8F2;
  --warning-medium: #FF6A00;
  --warning-dark: #DE4F00;

  --success-subtle-transparent: #00EB9E0F;
  --success-subtle: #F0FEF9;
  --success-medium: #00B272;
  --success-dark: #00905D;

  --danger-subtle-transparent: #FF000A08;
  --danger-subtle: #FFF7F8;
  --danger-medium: #ED1C24;
  --danger-dark: #CC161C;

  --box-shadow-bottom: #30343B33;

  --support-green: #8FBC8F;
}