/*------------------------------------*\
  Breadcrumb
\*------------------------------------*/

.breadcrumb {
  padding: var(--base-spacing-threequarters)  0;
  list-style: none;
}

.breadcrumb__item {
  display: inline;
  font-size: 1.125rem;

  +:before {
    padding: var(--base-spacing-half) ;
    color: var(--grey-medium-600);
    content: "/\00a0";
  }
}

.breadcrumb__link {
  color: var(--grey-medium-600);
  text-decoration: none;

  &:hover,
  &:active {
    color: var(--primary-dark);
    text-decoration: underline;
  }
  &:focus-visible {
    box-shadow: var(--base-focus-box-shadow);
    outline:none;
  }
}