.loading-spinner__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner.small {
  --spinner-size: 24px;
}

.loading-spinner.medium {
  --spinner-size: 32px;
}

.loading-spinner.large {
  --spinner-size: 64px;
}

.loading-spinner.light-bg {
  --spinner-track-color: var(--grey-subtle-028-transparent);
  --spinner-color: var(--primary-medium);
}

.loading-spinner.dark-bg {
  --spinner-track-color: rgba(255, 255, 255, 0.15);
  --spinner-color: var(--white);
}

.loading-spinner {
  position: relative;
  width: var(--spinner-size);
  height: var(--spinner-size);
}

.loading-spinner div {
  position: absolute;
  width: var(--spinner-size);
  height: var(--spinner-size);
  border: calc(var(--spinner-size) * 0.2) solid transparent;
  border-radius: 50%;

  &:nth-child(1),{
    border-color: var(--spinner-track-color)
  }

  &:nth-child(2), &:nth-child(3) {
    animation: loading-spinner 1.1s cubic-bezier(0.4, 0, 0.4, 0.8) infinite;
    border-left-color: var(--spinner-color);
  }

  &:nth-child(2) {
    opacity: 0.5;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes loading-spinner {
  to {
    transform: rotate(360deg);
  }
}