/*------------------------------------*\
  Checkbox
\*------------------------------------*/

$checkbox-height-after: 0.375rem;
$checkbox-width-after: 0px;
$checkbox-top-after: 0.625rem;
$checkbox-left-after: 0.375rem;

$checkbox-width-after-checked: 0.75rem;
$checkbox-top-after-checked: 0.3125rem;
$checkbox-left-after-checked: 0.25rem;


/** Input checkbox
  *
  * 1. Outer checkbox square.
  * 2. Inner checkbox checkmark.
  * 3. Outer square checked state.
  * 4. Checkmark checked input state
  * 5. Checkmark disabled checked input state
  */

.input--checkbox {
  color: var(--grey-dark-800);
  div {

    &:before { /* [1] */
      border-radius: var(--base-border-radius);
      transition: background-color $base-transition,
                  border-color $base-transition;
    }

    &:focus-visible:before {
        box-shadow: var(--base-focus-box-shadow);
    }

    &:after { /* [2] */
      border: 2px solid transparent;
      border-right: none;
      border-top: none;
      content: '';
      height: $checkbox-height-after;
      left: $checkbox-left-after;
      position: absolute;
      top: $checkbox-top-after;
      transform: rotate(-45deg);
      width: $checkbox-width-after;
      transition: border-color $base-transition,
                  width $base-transition,
                  top $base-transition,
                  left $base-transition;
    }
  }
  
  input {
    &:checked {
      + div {
        &:before { /* [3] */
          background-color: var(--primary-medium);
        }

        &:after { /* [4] */
          border-color: var(--white);
          left: $checkbox-left-after-checked;
          top: $checkbox-top-after-checked;
          width: $checkbox-width-after-checked;
        }
      }
    }

      &:disabled:checked {
      + div {
        &:after { /* [5] */
          border-color: var(--white);
        }
        &:before {
          border-color: var(--white);
          background-color: var(--grey-light-150);
        }
      }
    }
  }
}