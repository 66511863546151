.call-to-action {
  border-top: 1px solid var(--grey-medium-600);
  border-bottom: 1px solid var(--grey-medium-600);
  padding: var(--base-spacing-double)  var(--base-spacing-double)  0 var(--base-spacing-double) ;
}

.call-to-action__button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom:var(--base-spacing-double) ;
}