@use "sass:math";
/*------------------------------------*\
  Input password
\*------------------------------------*/

.input--password__toggle {
  position: absolute;
  right: var(--base-spacing-half) ;
  top: 0.75rem;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  
  i {
    color: var(--grey-medium-600);
  }

  &:hover,
  &:focus,
  &:active {
    i {
      color: var(--grey-dark-800);
    }
  }

  &:focus-visible {
    box-shadow: var(--base-focus-box-shadow);
  }
}
