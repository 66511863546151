/*------------------------------------*\
  Pagination
\*------------------------------------*/

.pagination {
  list-style: none;
  display: inline-flex;
  margin: 0;
  padding: 0;
}

.pagination__item {
  margin: 0;
  padding: 0;

  &:not(:last-of-type) {
    margin-right: var(--base-spacing) ;
  }
}

.pagination__link {
  font-weight: var(--font-weight-semi-bold);
  padding: var(--base-spacing) ;
  display: inline-block;

  color: var(--primary-medium);
  text-decoration: none;
}