.hero-call-to-action {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  padding-bottom: 48px;

  .hero-call-to-action__photo {
    margin-bottom: 0;
    img {
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      font-family: 'object-fit: cover; object-position: center;';
    }
  }

  .single-photo {
    overflow: hidden;
    width: 100%;
  }
}

.hero-call-to-action__blue {
  position: relative;
  background-color: #009fdf;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  color: white;
  padding: 32px;
  word-wrap: break-word;
}

.ccv-sg-hero-call-to-action__content {
  padding: 24px;
  bottom: -48px;
  right: 16px;
  position: absolute;
}

@include media-breakpoint-up(md) {
  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .hero-call-to-action .hero-call-to-action__blue {
    position: absolute;
    bottom: -100px;
  }

  .hero-call-to-action__content {
    color: white;
    width: 100%;
    top: 80%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    position: absolute;

    .container {
      position: relative;
    }
  }
}

@include media-breakpoint-down(sm) {
  .hero-call-to-action .hero-call-to-action__photo {
    margin-bottom: -48px;
  }

  .hero-call-to-action__blue {
    top: -15px;
  }
}



