/*------------------------------------*\
  Elements
\*------------------------------------*/


/**
  * HTML
  *
  * 1. IE11 & Edge make the scrollbar overlap the content and automatically hide itself when not in use.
  * Unfortunately, the auto-showing of the scrollbar is sometimes too sensitive,
  * thus making it hard to click on stuff near the right edge of the page.
  * So we add this style to force IE11 & Edge to use a "normal", non-overlapping, non-auto-hiding scrollbar.
  * See https://github.com/twbs/bootstrap/issues/18543
  * and https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/7165383/
  *
  * 2. Changes the default tap highlight to be completely transparent in iOS.
  */

html {
  -ms-overflow-style: scrollbar; /* [1] */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* [2] */
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  @include text-medium;
  font-weight: var(--base-font-weight);
  font-family: var(--base-font-family);
  color: var(--grey-dark-800);
  background-color: var(--white);
  margin: 0;
}


/**
  * Headings, text & dividers
  */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: var(--grey-dark-800);
}

hr {
  margin-top: var(--base-spacing) ;
  margin-bottom: var(--base-spacing) ;
  border: 0;
  border-top: var(--base-border);
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

blockquote {
  margin: 0 0 var(--base-spacing) ;
}


/**
  * Lists
  */

ol,
ul,
dl {
  margin: 0;
  padding-left: var(--base-spacing);
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}


/**
  * Inputs & Forms
  *
  * 1. Avoid 300ms click delay on touch devices that support the `touch-action` CSS property.
  * In particular, unlike most other browsers, IE11+Edge on Windows 10 on touch devices and IE Mobile 10-11
  * DON'T remove the click delay when `<meta name="viewport" content="width=device-width">` is present.
  * However, they DO support removing the click delay via `touch-action: manipulation`.
  * See:
  * - https://v4-alpha.getbootstrap.com/content/reboot/#click-delay-optimization-for-touch
  * - http://caniuse.com/#feat=css-touch-action
  * - https://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay
  *
  * 2. Textareas should only resize vertically so they don't break their (horizontal) containers.
  */

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; /* [1] */
}

label {
  margin-bottom: 0;
}

textarea {
  resize: vertical; /* [2] */
}

button,
input,
optgroup,
select,
textarea {
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);
}