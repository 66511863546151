/*------------------------------------*\
  Contentlist
\*------------------------------------*/

// TODO:
// 1. Validate header design with UX.
// 2. Cleanup code.

.contentlist {
  margin: 0;
  margin-left: -var(--base-spacing-threequarters) ;
  margin-bottom: var(--base-spacing-double) ;
  padding: 0;
  list-style: none;

  .heading-xsmall {
    margin-bottom: var(--base-spacing-half) ;
    padding-left: 0;
    color: var(--primary-dark);
    transition: padding-left $base-transition,
                color $base-transition;
  }

  .text-medium {
    padding: 0;
    margin: 0;
  }
}

.contentlist__item {
  margin: 0;
  padding: 0;
}

.contentlist__link {
  text-decoration: none;
  padding: var(--base-spacing-threequarters) ;
  border-radius: 6px;
  display: block;
  background-color: transparent;
  position: relative;

  transition: background-color $base-transition;
  
  .icon {
    position: absolute;
    top: var(--base-spacing-threequarters) ;
    left: 0px;
    opacity: 0;
    fill: var(--primary-medium);

    transition: left $base-transition,
                opacity $base-transition;
  }

  &:hover {
    background-color: var(--primary-subtle-transparent);

    .heading-xsmall {
      padding-left: var(--base-spacing-oneandhalf) ;
      color: var(--primary-medium);
    }

    .icon {
      left: 9px;
      opacity: 1;
      color: var(--primary-medium);
    }
  }
}

.contentlist__marketing {

  img {
    width: 100%;
    border-top-right-radius: var(--base-spacing) ;
    border-bottom-left-radius: var(--base-spacing) ;
    margin-bottom: var(--base-spacing) ;
  }
}