.label {
  display:flex;
  justify-content: flex-start;
  column-gap: var(--base-spacing-half);
}

.label--container {
  padding-bottom: var(--base-spacing-quarter);
  display:flex;
  flex-direction: column;
}