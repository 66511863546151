/*------------------------------------*\
  Text
\*------------------------------------*/


@mixin text-large {
  font-size: 1.375rem;
  line-height: var(--base-line-height);
}

@mixin text-medium {
  font-size: 1.0625rem;
  line-height: var(--base-line-height);
}

@mixin text-small {
  font-size: 0.875rem;
  line-height: 170%;
}

@mixin text-xsmall {
  font-size: 0.75rem;
  line-height: var(--base-line-height);
}