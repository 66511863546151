/*------------------------------------*\
  Badge
\*------------------------------------*/

$badge-size: 0.9375rem;
$badge-inset: 0.25rem;

/**
  * Basic badge
  *
  * 1. Note: a badge always needs a relatively positioned parent.
  */

.badge {
  font-size: 0.6875rem;
  line-height: $badge-size;
  border-radius: $badge-size;
  display: block;
  height: $badge-size;
  min-width: $badge-size;
  padding: 0 var(--base-spacing-quarter) ;
  position: absolute; /* [1] */
  right: -$badge-inset;
  text-align: center;
  top: -$badge-inset;
  width: auto;
  z-index: inherit + 1;
}

.badge--orange {
  background: var(--warning-medium);
  color: var(--white);
  font-size: 0.75rem;
}

.badge--light-orange {
  background: var(--warning-subtle-transparent);
  color: var(--warning-medium);
  font-size: 0.75rem;
}

.badge--clear {
  font-weight: bold;
}