/* INPUT SLIDER */

.input.input--slider {
    margin: 32px 0;
}

.input.input--slider .input--slider__range {
    position: relative;
}

.input.input--slider .input--slider__range__input {
    display: inline-block;
    -webkit-appearance: none;
    height: 8px;
    width: 100%;
    margin: var(--base-spacing-oneandhalf)  0 var(--base-spacing) 0;
    padding: 0;
    border-radius: 20px;
    background-color: var(--primary-medium);
    box-sizing: content-box;


    &:focus-visible::-webkit-slider-thumb {
        box-shadow: var(--base-focus-box-shadow);
    }
    &:focus-visible::-moz-range-thumb, {
        box-shadow: var(--base-focus-box-shadow);
    }
    &:focus-visible::-ms-thumb, {
        box-shadow: var(--base-focus-box-shadow);
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 24px;
        height: 24px;
        border: 1px solid var(--primary-medium);
        border-radius: 25px;
        background-color: var(--white);
        box-shadow: -1px 0px 1px 0px var(--white);
        &:focus-within {
            box-shadow: var(--base-focus-box-shadow);
        }
    }

    &::-moz-range-thumb {
        width: 24px;
        height: 24px;
        border: 2px solid var(--primary-medium);
        border-radius: 25px;
        background-color: var(--white);
        box-shadow: -1px 0px 1px 0px var(--white);
        &:focus-within {
            box-shadow: var(--base-focus-box-shadow);
        }
    }

    &::-ms-thumb {
        width: 24px;
        height: 8px;
        border-radius: 0;
        box-shadow: none;
        background-color: var(--grey-medium-600);
        border-color: var(--white);
    }

    &:hover {
        cursor: pointer;
    }


    &::-webkit-slider-thumb:hover {
        cursor: pointer;
    }
    
    &::-moz-range-thumb:hover {
        cursor: pointer;
    }

    &::-ms-thumb:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }

    &:disabled {
        cursor: default;
    }

    &:disabled::-webkit-slider-thumb {
        cursor: default;
    }

    &:disabled::-moz-range-thumb {
        cursor: default;
    }

    &:disabled::-ms-thumb {
        cursor: default;
    }
}

.input.input--slider label:hover {
    cursor: default;
}


.input.input--slider .input--slider__range__value {
    position: absolute;
    top: -10px;
    left: 50%;
    color: var(--primary-medium);
    font-weight: bold;
}

.input.input--slider .input--slider__labels {
    display: flex;
    justify-content: space-between;
}

.input--slider__number-input {
    width: 20%;
    min-width: 100px;
    max-width: 300px;
    height: 30px;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
}

input.input--slider__number-input__input {
    width: calc(100% - 16px);
    height: 100%;
    padding: var(--base-spacing-quarter)  var(--base-spacing-threequarters) ;
}