@use "sass:math";

/*------------------------------------*\
  Input text
\*------------------------------------*/

$input-text-height: 3rem;


/**
  * Generic text input
  */

.input--text {
  input {
    height: $input-text-height;
  }

  input, textarea {
    font-size: var(--base-font-size);
    font-weight: var(--base-font-weight);
    line-height: 1.375rem;
    color: var(--grey-dark-800);
    border-radius: var(--base-border-radius);
    border: var(--base-border);
    display: block;
    padding: var(--base-spacing-threequarters) ;
    position: relative;
    width: 100%;

    &::placeholder {
      color: var(--grey-medium-400);
    }

    &:hover {
      border-color: var(--primary-light);
    }

    &:focus-visible {
      border-color: var(--primary-medium);
      box-shadow: var(--base-focus-box-shadow);
      outline: none;
    }

    &:disabled, &:read-only {
      color: var(--grey-medium-400);
      background-color: var(--grey-subtle-028);
      border-color: var(--grey-subtle-028-transparent);
    }
  }

  > i, > label > i {
    display: none;
    position: absolute;
    right: var(--base-spacing-half) ;
    bottom: 0.75rem;

    &.success {
      color: var(--success-medium);
    }

    &.error {
      color: var(--danger-medium)
    }
  }

  input.error, textarea.error  {
    border-color: var(--danger-medium);

    ~ i.error {
      display: block;
    }
  }

  input.success, textarea.success {
    ~ i.success {
      display: block;
    }
  }
}