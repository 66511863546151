/*------------------------------------*\
  Lozenge
\*------------------------------------*/

/**
  * Basic lozenge
  */

.lozenge, .lozenge--default {
  font-weight: var(--font-weight-semi-bold);
  text-transform: uppercase;
  font-size:  0.875rem;
  line-height: 170%;

  background: var(--grey-subtle-028-transparent);
  color: var(--grey-dark-800);
  border-radius: var(--base-border-radius);
  display: inline-block;
  padding: 3px 14px;
  height: fit-content;
  width:fit-content;

  &.lozenge--bold {
    background: var(--grey-medium-600);
    color: var(--white);
  }
}

/**
  * Lozenge colors
  */

.lozenge--alert, .lozenge--warning {
  background: var(--warning-subtle-transparent);
  color: var(--warning-medium);

  &.lozenge--bold {
    background: var(--warning-medium);
    color: var(--white);
  }
}

.lozenge--success {
  background: var(--success-subtle-transparent);
  color: var(--success-medium);

  &.lozenge--bold {
    background: var(--success-medium);
    color: var(--white);
  }
}

.lozenge--error, .lozenge--danger {
  background: var(--danger-subtle-transparent);
  color: var(--danger-medium);

  &.lozenge--bold {
    background: var(--danger-medium);
    color: var(--white);
  }
}

.lozenge--info, .lozenge--info-dark {
  background: var(--primary-subtle-transparent);
  color: var(--primary-dark);

  &.lozenge--bold {
    background: var(--primary-dark);
    color: var(--white);
  }
}

.lozenge--info-light {
  background: var(--primary-subtle-transparent);
  color: var(--primary-medium);

  &.lozenge--bold {
    background: var(--primary-medium);
    color: var(--white);
  }
}

.lozenge--small {
  font-size: 0.75rem;
  font-weight: 450;
  padding: 1px 10px;
}
