/*------------------------------------*\
  Heading
\*------------------------------------*/

@mixin base-heading {
  font-family: "Euclid Circular B", "Arial", sans-serif;
  letter-spacing: normal;
  line-height: normal;
  font-weight: 500;
}

@mixin heading-hero {
  font-family: "Euclid Circular B SemiBold", "Arial", sans-serif;
  letter-spacing: normal;
  line-height: normal;
  font-weight: 600;
  font-size: 3.75rem;
}

@mixin heading-x-large {
  @include base-heading;
  font-size: 2.75rem;
}

@mixin heading-large {
  @include base-heading;
  font-size: 2.125rem;
}

@mixin heading-medium {
  @include base-heading;
  font-size: 1.625rem;
}

@mixin heading-small {
  @include base-heading;
  font-size: 1.25rem;
}

@mixin heading-x-small {
  @include base-heading;
  font-size: 1.0625rem;
}

@mixin heading-xx-small {
  @include base-heading;
  font-size: 0.9375rem;
}