/*------------------------------------*\
  Icon
\*------------------------------------*/

/**
  * Icons are based on Material Design
  * https://material.io/tools/icons/?style=baseline
  * 
  * Default markup: {{> Components-Icons }}
  */

i {
  vertical-align: text-bottom;
}  

