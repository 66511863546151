/*------------------------------------*\
  Spacing
\*------------------------------------*/

/**
  * Base spacing unit
  */

:root,
:before,
:after {
  $base-spacing: 16px;
  --base-spacing-eighth:             #{$base-spacing * 0.125}; // 2px
  --base-spacing-quarter:            #{$base-spacing * 0.25};  // 4px
  --base-spacing-half:               #{$base-spacing * 0.5};   // 8px
  --base-spacing-threequarters:      #{$base-spacing * 0.75};  // 12px

  --base-spacing: #{$base-spacing};

  --base-spacing-oneandaquarter:      #{$base-spacing * 1.25};  // 04px
  --base-spacing-oneandhalf:          #{$base-spacing * 1.5};   // 24px
  --base-spacing-oneandthreequarters: #{$base-spacing * 1.75};  // 28px
  --base-spacing-double:              #{$base-spacing * 2};     // 32px
  --base-spacing-twoandhalf:          #{$base-spacing * 2.5};   // 40px
  --base-spacing-triple:              #{$base-spacing * 3};     // 48px
  --base-spacing-quadruple:           #{$base-spacing * 4};     // 64px
  --base-spacing-sextuple:            #{$base-spacing * 6};     // 96px
  --base-spacing-decuple:             #{$base-spacing * 10};    // 160px
}