/*------------------------------------*\
  Grid
\*------------------------------------*/

$enable-flex: true;
$enable-grid-classes: true;


/**
  * Container
  */

@if $enable-grid-classes {
  .container {
    @include make-container();
    @include make-container-max-widths();
    transition: width $base-transition,
                max-width $base-transition,
                margin-left $base-transition,
                margin-right $base-transition;
  }
}


/**
  * Container Fluid
  */

@if $enable-grid-classes {
  .container-fluid {
    width: 100%;
    @include make-container();
    transition: max-width $base-transition;
  }
}


/**
  * Row
  *
  * 1. Remove the negative margin from default .row, then the horizontal padding
  *    from all immediate children columns (to prevent runaway style inheritance).
  */

@if $enable-grid-classes {
  .row {
    @include make-row();
  }

  .no-gutters { /* [1] */
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}


/**
  * Column
  */

@if $enable-grid-classes {
  @include make-grid-columns();
}
