@use "sass:math";

/*------------------------------------*\
  Tooltip
\*------------------------------------*/

// TODO: tabbable
// TODO: kruisje op mobiel?


/**
  * Basic tooltip
  */

$toggle-size: 24px;
$tooltip-width: 400px;
$triangle-size: 16px;
$triangle-border: math.div($triangle-size, 2);

$horizontal-tooltipbody-offset: - math.div($tooltip-width, 2) + math.div($toggle-size, 2); /* [1] */
$vertical-triangle-offset: math.div(($toggle-size - $triangle-size), 2);
$vertical-tooltipbody-offset: 16px;
$vertical-tooltiptriangle-offset: $vertical-tooltipbody-offset + $vertical-triangle-offset;

$tooltip-background: var(--grey-medium-600);

.tooltip {
  display: inline-block;
  position: relative;
  padding: 0;
  line-height: 1;
  
  &.expanded {
    .tooltip-text__toggle-button {
      display: none;

      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    .tooltip__body {
      display: block;
    }

    .tooltip__close-button {
      display: block;
    }
  }
}

.tooltip__collapsed {
  cursor: pointer;

  &:focus-visible {
    box-shadow: var(--base-focus-box-shadow);
    outline:none;
  }
}

.tooltip__toggle-button,
.tooltip__close-button {
  border: 0;
  outline: 0;
  padding:0;
  line-height: 1;
  background-color: transparent;
  cursor: pointer;

  i {
    cursor: pointer;
  }

}

.tooltip__toggle-button {
  i {
    color: var(--grey-medium-400);
  }
}

.tooltip__close-button {
  display: none;
  color: var(--white);
  position: absolute;
  right: var(--base-spacing-quarter);
  top: var(--base-spacing-quarter);
  padding: var(--base-spacing-quarter);
  margin: var(--base-spacing-half);

  &:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 4px var(--white);
  }
}

.tooltip__body {
  display: none;
  position:fixed;
  z-index: 98;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow-x: scroll;
  padding: var(--base-spacing-oneandhalf) ;
  text-align: left;
  color: var(--white);
  background-color: $tooltip-background;
  border-radius: 0;
  line-height: var(--base-line-height);


  &:after {
    content: '';
    border: $triangle-border transparent solid;
    display: none;
    position: absolute;

    @include media-breakpoint-up(md) {
      display:block;
    }
  }

  @include media-breakpoint-up(md) {
    bottom: auto;
    left: auto;
    right: auto;
    top: auto;
    overflow-x: unset;
    position: absolute;
    width: $tooltip-width;
    height: auto;
    border-radius: var(--base-border-radius);
  }
}

.tooltip__text {
  margin-bottom: var(--base-spacing-half);
}

.tooltip__title {
  color: var(--white);
  margin-right: var(--base-spacing-triple);
}

/**
  * Tooltip top
  */
.tooltip--top {
  .tooltip__body {
    @include media-breakpoint-up(md) {
      bottom: var(--base-spacing-twoandhalf);
      left: $horizontal-tooltipbody-offset;
    }

    &:after {
      border-top-color: $tooltip-background;
      left: 50%;
      margin-left: -($triangle-border);
      top: 100%;
    }
  }
}

/**
  * Tooltip bottom
  */
.tooltip--bottom {
  .tooltip__body {
    @include media-breakpoint-up(md) {
      left: $horizontal-tooltipbody-offset;
      top: var(--base-spacing-twoandhalf);
    }

    &:after {
      border-bottom-color: $tooltip-background;
      bottom: 100%;
      left: 50%;
      margin-left: -($triangle-border);
    }
  }
}

/**
  * Tooltip left
  */
.tooltip--left {
  .tooltip__body {
    @include media-breakpoint-up(md) {
      right: var(--base-spacing-twoandhalf);
      top: -($vertical-tooltipbody-offset);
    }

    &:after {
      border-left-color: $tooltip-background;
      left: 100%;
      top: $vertical-tooltiptriangle-offset;
    }
  }
}

/**
  * Tooltip right
  */
.tooltip--right {
  .tooltip__body {
    @include media-breakpoint-up(md) {
      left: var(--base-spacing-twoandhalf) ;
      top: -($vertical-tooltipbody-offset);
    }

    &:after {
      border-right-color: $tooltip-background;
      right: 100%;
      top: $vertical-tooltiptriangle-offset;
    }
  }
}
