/*------------------------------------*\
  CCV Stylesheet
\*------------------------------------*/

@import
  "1-settings/settings",
  "2-tools/tools",
  "3-generic/generic",
  "4-elements/elements",
  "5-objects/objects",
  "6-components/components",
  "7-utilities/utilities"
;


@font-face {
  font-family: "Euclid Circular B SemiBold";
  src: url("../fonts/EuclidCircularB-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "Euclid Circular B";
  src: url("../fonts/EuclidCircularB-Medium.otf") format("opentype");
}


//Self hosted Google Fonts

/* cyrillic-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300 650;
  font-display: swap;
  src: url("../fonts/SourceSans3-Cyrillic-Extended.woff2") format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300 650;
  font-display: swap;
  src: url("../fonts/SourceSans3-Cyrillic.woff2") format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300 650;
  font-display: swap;
  src: url("../fonts/SourceSans3-Greek-Extended.woff2") format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300 650;
  font-display: swap;
  src: url("../fonts/SourceSans3-Greek.woff2") format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300 650;
  font-display: swap;
  src: url("../fonts/SourceSans3-Vietnamese.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300 650;
  font-display: swap;
  src: url("../fonts/SourceSans3-Latin-Extended.woff2") format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300 650;
  font-display: swap;
  src: url("../fonts/SourceSans3-Latin.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* fallback */
@font-face {
  font-family: 'Material Symbols Rounded';
  font-style: normal;
  font-weight: 100 700;
  src: url("../fonts/MaterialSymbols.woff2") format('woff2');
}

.material-symbols-rounded {
  font-family: 'Material Symbols Rounded';
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}