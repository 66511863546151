/*------------------------------------*\
  Toggle
\*------------------------------------*/

$toggle-height: 32px;
$toggle-width: 52px;

$toggle-inner-spacing: 2px;
$toggle-grow: 4px;


/** Input toggle
  *
  * 1. To align label and input, input size is used as line-height.
  * 2. Outer base.
  * 3. Inner circle.
  * 4. Determine the size of the inner circle based on the outer size.
  * 5. Prevent using 50%, otherwise ellipse formed shape occurs.
  * 6. Lets the inner circle grow in width on mouse down.
  * 7. Outer base checked input state.
  * 8. Inner circle checked input state.
  * 9. As the inner circle is growing to the left, and is left aligned,
  *    toggle-grow has to be subtracted from the alignment.
  */

.input--toggle {
  div {
    @include text-medium;
    height: $toggle-height;
    line-height: $toggle-height; /* [1] */
    padding-left: calc(#{$toggle-width} + var(--base-spacing-half));
    transition: color $transition-half;
    position: relative;

    &:empty {
      padding-left: 0;
      height: $toggle-height;
      width: $toggle-width;
    }

    &:before { /* [2] */
      background-color: var(--grey-medium-400);
      border-radius: $toggle-height;
      content: '';
      height: $toggle-height;
      left: 0;
      position: absolute;
      top: 0;
      width: $toggle-width;
      transition: background-color $transition-half;
    }
    &:focus-visible:before {
      box-shadow: var(--base-focus-box-shadow);
    }

    &:after { /* [3] */
      background-color: var(--white);
      border-radius: $toggle-height - ($toggle-inner-spacing * 2); /* [5] */
      content: '';
      height: $toggle-height - ($toggle-inner-spacing * 2); /* [4] */
      left: $toggle-inner-spacing;
      position: absolute;
      top: $toggle-inner-spacing;
      width: $toggle-height - ($toggle-inner-spacing * 2); /* [4] */
      transition: left $transition-half,
                  width $transition-half;
    }

    &:active:after {
      width: ($toggle-height - ($toggle-inner-spacing * 2)) + $toggle-grow; /* [6] */
    }

    &:hover, &:focus-visible {
      color: var(--primary-medium);
      text-decoration: underline;
      outline: none;
    }
  }

  input {
    &:checked {
      + div {
        &:before { /* [7] */
          background-color: var(--primary-medium);
        }

        &:after { /* [8] */
          left: $toggle-width - $toggle-height + $toggle-inner-spacing;
        }

        &:active:after {
          left: $toggle-width - $toggle-height + $toggle-inner-spacing - $toggle-grow; /* [9] */
        }
      }
    }

    &:disabled {
      + div {
        color: var(--grey-medium-400);
        text-decoration: none;

        &:before {
          background-color: var(--grey-light-150);
        }
      }
    }
  }
}
