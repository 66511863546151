/*------------------------------------*\
  Codesnippet
\*------------------------------------*/

.prettyprint {
  background: var(--grey-dark-800) !important;
  font-family: Menlo, "Bitstream Vera Sans Mono", "DejaVu Sans Mono", Monaco, Consolas, monospace !important;
  border: 0 !important;
  padding: var(--base-spacing) !important;
  white-space: pre-line;
}

.pln {
  color: #c5c8c6 !important;
}

ol.linenums {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  color: #969896 !important;
}

li.L0,
li.L1,
li.L2,
li.L3,
li.L4,
li.L5,
li.L6,
li.L7,
li.L8,
li.L9 {
  padding-left: 1em !important;
  background-color: var(--grey-dark-800) !important;
  list-style-type: decimal !important;
}

@media screen {

  /* string content */

  .str {
    color: #b5bd68 !important;
  }

  /* keyword */

  .kwd {
    color: #b294bb !important;
  }

  /* comment */

  .com {
    color: #969896 !important;
  }

  /* type name */

  .typ {
    color: #81a2be !important;
  }

  /* literal value */

  .lit {
    color: #de935f !important;
  }

  /* punctuation */

  .pun {
    color: #c5c8c6 !important;
  }

  /* lisp open bracket */

  .opn {
    color: #c5c8c6 !important;
  }

  /* lisp close bracket */

  .clo {
    color: #c5c8c6 !important;
  }

  /* markup tag name */

  .tag {
    color: #cc6666 !important;
  }

  /* markup attribute name */

  .atn {
    color: #de935f !important;
  }

  /* markup attribute value */

  .atv {
    color: #8abeb7 !important;
  }

  /* declaration */

  .dec {
    color: #de935f !important;
  }

  /* variable name */

  .var {
    color: #cc6666 !important;
  }

  /* function name */

  .fun {
    color: #81a2be !important;
  }
}