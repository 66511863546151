/*------------------------------------*\
  Progressbar
\*------------------------------------*/

$progressindicator-van-height: 27px;
$progressindicator-van-width: 46px;

.progressbar {
  border-bottom: var(--base-border);
  height: $progressindicator-van-height;
  margin: var(--base-spacing-double)  0;
}

.progressbar__indicator {
  border-bottom: 2px solid var(--primary-medium);
  display: inline-block;
  height: $progressindicator-van-height;
  position: relative;
  text-align: right;
  transition: width $transition-double;
  width: 10%;
  
  svg {
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
  }
}